<template>
  <div v-if="config">
    <div
      v-if="content.MenuExtFoodDayOfWeek===null"
      style="padding: 0 20px;"
    >
      <week-menu 
        :disabled="loading"
        :primaryColor="config.BGColor"
        :secondaryColor="config.BGColorSecundary"
        :day="addDays"
        :locale="locale"
        :onChange="handleChangeDayOfWeek"
      />
    </div>
    <v-expansion-panels accordion v-if="filteredMenus">
      <menu-ext-item
        v-for="(item,i) in filteredMenus"
        :key="i"
        :menu="item"
        :locale="locale"
        :config="config"
        :dtouchWorkspace="dtouchWorkspace"
      />
    </v-expansion-panels>
  </div>
</template>
<script>
import WeekMenu from './WeekMenu'
import MenuExtItem from './MenuExtItem'
export default {
  name:'Item49MenuExtFood',
  components: {
    WeekMenu,
    MenuExtItem,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menus: null,
    filteredMenus: null,
    addDays: 0,
    loading: false,
  }),
  computed: {
    dtouchWorkspace () {
      return this.$store.state.app.dtouchWorkspace
    },
  },
  watch: {
    content () {
      this.handleFilterMenus()
    },
    'content.MenuExtFoodExternalID' () {
      this.handleFilterMenus()
    },
    'content.MenuExtFoodDayOfWeek' () {
      this.handleFilterMenus()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleChangeDayOfWeek (v) {
      this.addDays = v
      this.handleGetData()
    },
    handleFilterMenus () {
      const IDs = this.content.MenuExtFoodExternalID ? typeof this.content.MenuExtFoodExternalID === 'string' ? this.content.MenuExtFoodExternalID.split(',') : this.content.MenuExtFoodExternalID : null

      let aux = JSON.parse(JSON.stringify(this.menus))
      const today = new Date()
      const dayOfWeek = new Date(today.setDate(today.getDate() + this.addDays)).getDay()
      if (this.content.MenuExtFoodDayOfWeek) aux = aux.filter(x => x.DaysOfWeek.indexOf(this.content.MenuExtFoodDayOfWeek) >= 0)
      else aux = aux.filter(x => x.DaysOfWeek.indexOf(dayOfWeek) >= 0)

      if (!IDs) this.filteredMenus = aux
      else this.filteredMenus = aux.filter(x => IDs.indexOf(x.ExternalID) >= 0)
    },
    handleGetData () {
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/workspaces/${this.dtouchWorkspace}/menu-ext/`
      fetch(url, { 
        method: 'get', 
        headers: new Headers({
          'Content-Type': 'application/json'
        }), 
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        this.menus = data
        this.handleFilterMenus()
      })
    },
  }
}
</script>

