<template>
  <v-expansion-panel
    class="menu-ext-panel"
    @change="handleExpand"
  >
  <v-expansion-panel-header
    expand-icon=""
    style="padding: 0;border-radius: 0"
    :style="{ 
            'font-family': isSecundary ? config.FontSecundary : config.Font,
            'font-size': isSecundary ? `${config.FontSizeSecundary}px` : `${config.FontSize}px`,
            'background-color': isSecundary ? config.BGColorSecundary : config.BGColor, 
            'color': isSecundary ? config.ColorSecundary : config.Color, 
            'background-image': ((isSecundary && config.GradientSubmenu) || (!isSecundary && config.Gradient)) ? 'linear-gradient(to top, rgba(128, 128, 128, 0.0), rgba(0, 0, 0, 0.2))' : '',
            'border-bottom': config.LineMenu ? `0.5px solid ${getColor()}` : '',
            'border-top': config.LineMenu ? `0.5px solid ${getColor()}` : ''
          }"
  >
    <menu-ext-header
      :name="menu.Name"
      :locale="locale"
      :config="config"
    />
  </v-expansion-panel-header>
    <v-expansion-panel-content v-if="dishes">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
      <div
          v-for="(item, i) of dishes"
          :key="i"
      >
      <center 
        v-if="item.Name"
        class="submenu-dishes"
        :style="{
          'font-family': config.FontContent,
          'font-size': `${config.FontSizeContent}px`,
          color: config.BGColorSecundary,
        }"
      > 
        {{ getTranslation(item.Name, locale) }}
      </center>
        <menu-ext-dishe
          v-for="(dishe, i) of item.dishes"
          :key="i"
          :locale="locale"
          :data="dishe"
          :config="config"
          :dtouchWorkspace="dtouchWorkspace"
        />
      </div>
      <!--menu-ext-dishe
        v-for="(item, i) of dishes"
        :key="i"
        :locale="locale"
        :data="item"
        :config="config"
        :dtouchWorkspace="dtouchWorkspace"
      /-->
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import utils from '@/services/utils'
import MenuExtHeader from './MenuExtHeader'
import MenuExtDishe from './menuExtDishe/Index'
export default {
  components: {
    MenuExtHeader,
    MenuExtDishe,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    dtouchWorkspace: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isSecundary: true,
    loading: false,
    dishes: null,
  }),
  methods: {
    getColor () {
      if (this.isSecundary) {
        if (this.config.ColorSecundary && this.config.ColorSecundary.length >= 7) return this.config.ColorSecundary.substring(0, 7).concat('4d')
        return this.config.ColorSecundary
      } else {
        if (this.config.Color && this.config.Color.length >= 7) return this.Color.substring(0, 7).concat('4d') 
        return this.config.Color
      } 
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleExpand () {
      if (!this.dishes) this.handleGetDishes()
    },
    handleGetDishes () {
      this.loading = true
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/workspaces/${this.dtouchWorkspace}/menu-ext/${this.menu.ID}`
      fetch(url, { 
        method: 'get', 
        headers: new Headers({
          'Content-Type': 'application/json'
        }), 
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        this.dishes = data
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
  },
}
</script>
<style>
.submenu-dishes {
  text-align: center;
  padding: 12px 15px;
}
.menu-ext-panel {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: transparent !important;
}
.menu-ext-panel::before {
  webkit-box-shadow: none;
  box-shadow: none;
}
</style>
