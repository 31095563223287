var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{staticClass:"menu-ext-panel",on:{"change":_vm.handleExpand}},[_c('v-expansion-panel-header',{staticStyle:{"padding":"0","border-radius":"0"},style:({ 
          'font-family': _vm.isSecundary ? _vm.config.FontSecundary : _vm.config.Font,
          'font-size': _vm.isSecundary ? ((_vm.config.FontSizeSecundary) + "px") : ((_vm.config.FontSize) + "px"),
          'background-color': _vm.isSecundary ? _vm.config.BGColorSecundary : _vm.config.BGColor, 
          'color': _vm.isSecundary ? _vm.config.ColorSecundary : _vm.config.Color, 
          'background-image': ((_vm.isSecundary && _vm.config.GradientSubmenu) || (!_vm.isSecundary && _vm.config.Gradient)) ? 'linear-gradient(to top, rgba(128, 128, 128, 0.0), rgba(0, 0, 0, 0.2))' : '',
          'border-bottom': _vm.config.LineMenu ? ("0.5px solid " + (_vm.getColor())) : '',
          'border-top': _vm.config.LineMenu ? ("0.5px solid " + (_vm.getColor())) : ''
        }),attrs:{"expand-icon":""}},[_c('menu-ext-header',{attrs:{"name":_vm.menu.Name,"locale":_vm.locale,"config":_vm.config}})],1),(_vm.dishes)?_c('v-expansion-panel-content',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_vm._l((_vm.dishes),function(item,i){return _c('div',{key:i},[(item.Name)?_c('center',{staticClass:"submenu-dishes",style:({
        'font-family': _vm.config.FontContent,
        'font-size': ((_vm.config.FontSizeContent) + "px"),
        color: _vm.config.BGColorSecundary,
      })},[_vm._v(" "+_vm._s(_vm.getTranslation(item.Name, _vm.locale))+" ")]):_vm._e(),_vm._l((item.dishes),function(dishe,i){return _c('menu-ext-dishe',{key:i,attrs:{"locale":_vm.locale,"data":dishe,"config":_vm.config,"dtouchWorkspace":_vm.dtouchWorkspace}})})],2)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }