var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"sticky","border-radius":"0"},style:({ 'font-family': _vm.config.GlobalFont, 
      'background-color': _vm.isSecundary ? _vm.config.BGColorSecundary : _vm.config.BGColor, 
      'color': _vm.isSecundary ? _vm.config.ColorSecundary : _vm.config.Color,
      'background-image': ((_vm.isSecundary && _vm.config.GradientSubmenu) || (!_vm.isSecundary && _vm.config.Gradient)) ? 'linear-gradient(to top, rgba(128, 128, 128, 0.0), rgba(0, 0, 0, 0.2))' : ''
    })},[_c('v-layout',{staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"text-center",style:({ 
          'padding': !_vm.isSecundary ? '20px 10px 20px 10px !important' : '10px 40px 10px 40px !important',
          'font-family':  _vm.isSecundary ? _vm.config.FontSecundary : _vm.config.Font,
          'font-size': _vm.isSecundary ? ((_vm.config.FontSizeSecundary) + "px") : ((_vm.config.FontSize) + "px"),
          })},[_vm._v(" "+_vm._s(_vm.isSecundary ? (_vm.config.TextFormatSecundary === 1 ? _vm.getTranslation(_vm.name, _vm.locale).toUpperCase() : _vm.config.TextFormatSecundary === 2 ? _vm.getTranslation(_vm.name,_vm.locale).toLowerCase() : _vm.getTranslation(_vm.name, _vm.locale)) : (_vm.config.TextFormat === 1 ? _vm.getTranslation(_vm.name, _vm.locale).toUpperCase() : _vm.config.TextFormat === 2 ? _vm.getTranlation(_vm.name, _vm.locale).toLowerCase() : _vm.getTranslation(_vm.name, _vm.locale)))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }