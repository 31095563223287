<template>
   <div
      class="book-week-menu"
      :style="{ 'background-color': `${primaryColor}` }"
   >
      <v-btn-toggle 
         v-model="daysToAdd"
         style="width: 100%;"
         :style="{ 'background-color': `${primaryColor}` }"
         mandatory
      >
         <v-btn
            :disabled="disabled"
            v-for="(day, index) in week"
            :key="`week${index}`"
            style="width: 14.3%; min-width:14.2%;"
            :style="{ 
               'background-color': `${primaryColor}`, 
               'color': getOppositeColor(primaryColor) 
            }"
            @click="handleChangeDay(index)"   
         >
            <span :style="{ 'color': getOppositeColor(primaryColor) }">{{ $t(`common.${day}`, locale).substring(0, 2) }}</span>
         </v-btn>          
      </v-btn-toggle>
      <!--span
         :style="{ 'color': getOppositeColor(primaryColor)}"
      >
         <center style="margin-top:10px;padding-bottom:10px;">
            {{ $t(sDay.pre, locale).toUpperCase() }} {{ $t(sDay.month, locale).toUpperCase() }} {{ sDay.pos.toUpperCase() }}
         </center>
      </span-->
   </div>
</template>
<script>
import utils from '@/services/utils'
export default {
   props: {
      disabled: {
         type: Boolean,
         default: false, 
      },
      primaryColor: {
         type: String,
         required: true,
      },
      secondaryColor: {
         type: String,
         required: true,
      },
      day: {
         type: Number,
         required: true,
      },
      locale: {
         type: String,
         default: null,
      },
      onChange: {
         type: Function,
         required: true,
      },
   },
   data: () => ({
      week: [],
      daysToAdd: 0,
   }),
   computed: {
     sDay () {
         let dToday = new Date()
         const dActual = new Date().setDate(dToday.getDate() + this.daysToAdd)
         const pos = `${new Date(dActual).getDate()}, ${new Date(dActual).getFullYear()}`

         const result = {
            pre: this.daysToAdd === 0 ? 'common.today' : this.daysToAdd === 1 ? 'common.tomorrow' : '',
            month: `common.m${new Date(dActual).getMonth()}`,
            pos
         }
         return result
      },
   },
   mounted () {
      this.daysToAdd = this.day
      this.prepareWeek()
   },
   methods: {
      prepareWeek () {
         const dSemenaActual = new Date().getUTCDay() - 1
         const aux = []
         for (let i = 0; i < 7; i++) {
            let x = ((dSemenaActual + i) >= 7) ? (dSemenaActual + i - 7) : dSemenaActual + i
            if (x === -1) x = 6
            aux.push(`s${x}`)
         }
         this.week = aux
      },
      getOppositeColor (v) {
         return utils.getOppositeColor(v)
      },
      handleChangeDay (v) {
         this.daysToAdd = v
         this.onChange(v)
      },
   },
}
</script>
<style scoped>
.book-week-menu {
   border-radius: 5px;
   position: static;
   top: 0px;
   margin: 20px 0;
}
</style>

