<template>
  <div
    style="position: sticky;border-radius: 0;"
    :style="{ 'font-family': config.GlobalFont, 
        'background-color': isSecundary ? config.BGColorSecundary : config.BGColor, 
        'color': isSecundary ? config.ColorSecundary : config.Color,
        'background-image': ((isSecundary && config.GradientSubmenu) || (!isSecundary && config.Gradient)) ? 'linear-gradient(to top, rgba(128, 128, 128, 0.0), rgba(0, 0, 0, 0.2))' : ''
      }"
  >
    <v-layout
      wrap
      align-center
      justify-center
      style="position: relative; height: 100%;width: 100%;"
    >
      <v-flex xs12>
        <div
          class="text-center"
          :style="{ 
            'padding': !isSecundary ? '20px 10px 20px 10px !important' : '10px 40px 10px 40px !important',
            'font-family':  isSecundary ? config.FontSecundary : config.Font,
            'font-size': isSecundary ? `${config.FontSizeSecundary}px` : `${config.FontSize}px`,
            }"
        >          
          {{ isSecundary 
            ? (config.TextFormatSecundary === 1 ? getTranslation(name, locale).toUpperCase() : config.TextFormatSecundary === 2 ? getTranslation(name,locale).toLowerCase() : getTranslation(name, locale)) 
            : (config.TextFormat === 1 ? getTranslation(name, locale).toUpperCase() : config.TextFormat === 2 ? getTranlation(name, locale).toLowerCase() : getTranslation(name, locale))
          }}
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import utils from '@/services/utils'
  export default {
    props: {
      config: {
        type: Object,
        required: true,
      },
      name: {
        type: Object,
        require: true
      },
      locale: {
        type: String,
        required: true
      },
    },
    computed: {
      isSecundary () {
        return true
      },
      enterpriseID () {
        return this.$store.state.app.enterpriseID
      },
      enterpriseName () {
        return this.$store.state.app.enterpriseName
      },
      enterpriseColor () {
        return this.$store.state.app.enterpriseColor
      }
    },
    methods: {
      getColor () {
        if (this.isSecundary) {
          if (this.config.ColorSecundary && this.config.ColorSecundary.length >= 7) return this.config.ColorSecundary.substring(0, 7).concat('4d')
          return this.config.ColorSecundary
        } else {
          if (this.config.Color && this.config.Color.length >= 7) return this.Color.substring(0, 7).concat('4d') 
          return this.config.Color
        } 
      },
      getTranslation(v, locale) {
        return utils.getTranslation(v, locale)
      },
    }
  }
</script>
<style lang="scss">
  .mnuEdit {
    min-height: 20px;
    height: 25px !important;
    width: 25px !important;
  }
</style>
